import styled from 'styled-components'
import React from 'react'

export const TitleColumn = styled.div`
	max-width: 930px;
	margin-bottom: 24em;

	span {
		font: 1rem/1.3125rem 'RobotoMono Bold';
		text-transform: uppercase;
		margin-bottom: 16px;
		display: block;
		color: white;
		mix-blend-mode: difference;
	}

	@media (max-width: 1250px) {
		margin-bottom: 128px;
	}
`

export const Header = styled.h1`
	&.title {
		font-size: 2.625rem;
		line-height: 3.75rem;

		@media (max-width: 1250px) {
			font-size: 2.625rem;
			line-height: 3.75rem;
		}

		@media (max-width: 1024px) {
			font-size: 32px;
			line-height: 44px;
		}

		@media (max-width: 525px) {
			max-width: 322px;
			font-size: 30px;
		}
	}
`

export const Story = styled.div`
	.image-wrapp {
		margin-top: 36px;
		text-align: center;
	}

	@media (max-width: 1250px) {
		.image-wrapp {
			margin: 0;
			img {
				max-width: 240px;
			}
		}
	}

	@media (max-width: 768px) {
		margin-bottom: 128px;

		.image-wrapp {
			img {
				max-width: 180px;
			}
		}
	}

	@media (max-width: 525px) {
		margin-bottom: 90px;
	}
`
export const Looks = styled.div`
	margin-bottom: 256px;

	div.content {
		padding-right: 0;
		padding-left: 119px;
		padding-top: 81px;
		padding-bottom: 81px;

		.title {
			max-width: 100%;
		}
	}

	.content-item {
		position: relative;

		.image-wrapp {
			height: 100%;

			.gatsby-image-wrapper {
				width: 95%;
				position: absolute;
				top: 50%;
				right: -5%;
				transform: translateY(-50%);
			}
		}
	}

	div.s-image {
		padding-right: 0;
		flex-basis: calc(50% - 64px);
		max-width: calc(50% - 64px);
	}

	@media (max-width: 1250px) {
		margin-bottom: 128px;

		div.content {
			flex-basis: 100%;
			max-width: 100%;
			order: 0;
			padding: 0;
			margin-bottom: 48px;
		}

		div.s-image {
			flex-basis: 100%;
			max-width: 100%;
			order: 1;
			padding: 60px 0;

			.gatsby-image-wrapper {
				/* margin: 60px 0; */
				max-height: 100%;
				top: unset;
				transform: unset;
			}
		}
	}

	@media (max-width: 525px) {
		margin-bottom: 90px;
		margin-left: -20px;
		margin-right: -20px;
		width: calc(100% + 40px) !important;

		div.content {
			padding-left: 20px;
			padding-right: 20px;
			margin-bottom: 32px;
		}

		div.s-image {
			margin: 0;
			.gatsby-image-wrapper {
				width: 113%;
			}
		}
	}
`

export const Journey = styled.div`
	margin-bottom: 0 !important;

	.irc-content {
		padding-top: 73px;
		padding-bottom: 73px;

		.title {
			max-width: 100%;
		}
	}

	.content-item {
		position: relative;
	}

	.image-wrapp {
		height: 100%;
		.gatsby-image-wrapper {
			right: -1px;
			width: 95%;
			position: absolute;
			top: 50%;
			right: -6%;
			transform: translateY(-50%);
		}
	}

	@media (max-width: 1250px) {
		.irc-content {
			padding: 0;
			margin-bottom: 60px;
		}

		.image-wrapp {
			padding: 60px 0;
		}
	}

	@media (max-width: 525px) {
		margin-left: -20px;
		margin-right: -20px;
		width: calc(100% + 40px) !important;

		.irc-content {
			padding-left: 20px !important;
			padding-right: 20px !important;
			margin-bottom: 32px !important;
		}

		.image-wrapp {
			.gatsby-image-wrapper {
				width: 113%;
				right: 15px;
			}
		}
	}
`

/******************************************
 * Gatsby bug (file must export component)
 *****************************************/
const Style = () => {
	return <div>style</div>
}

export default Style
