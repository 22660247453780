import React, { useContext } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import HoverContext from '../../../context/HoverContext'

// Ui components
import Paragraph from '../Paragraph'
import Heading from '../Heading'
import CTA from '../CTA/index'
import Wysiwyg from '../Wysiwyg'
import Link from '../Link'

const ImageRightContentCta = React.memo((props) => {
	const { setCursorStyle } = useContext(HoverContext)

	const {
		Image,
		Title,
		Text,
		Services,
		GradientDark,
		GradientLight,
		ImageLink,
	} = props.fields

	return (
		<Flex {...props}>
			<Column className="irc-content">
				<Header as={Heading} level={props.level ? props.level : 3}>
					{Title}
				</Header>
				<div>
					{Text != null ? (
						/\r|\n/.exec(Text) ? (
							<Wysiwyg>{Text}</Wysiwyg>
						) : (
							<Paragraph>{Text}</Paragraph>
						)
					) : (
						Services.map((service) => {
							return <Paragraph key={service.id}>{service.Tag}</Paragraph>
						})
					)}
				</div>
				{props.fields.CTA != null && (
					<React.Fragment>
						{props.baseLink ? (
							<Link
								className={'base-link'}
								to={props.fields.Link}
								onMouseEnter={() => {
									setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
								}}
								onMouseLeave={() => {
									setCursorStyle({
										height: false,
										width: false,
										transition: false,
									})
								}}
							>
								{props.fields.CTA}
							</Link>
						) : (
							<CTA link={props.fields.Link}>{props.fields.CTA}</CTA>
						)}
					</React.Fragment>
				)}
			</Column>
			<Column
				className="content-item"
				gradientdark={GradientDark}
				gradientlight={GradientLight}
			>
				{ImageLink ? (
					<Link
						to={ImageLink}
						className="img-link"
						onMouseEnter={() => {
							setCursorStyle({ height: 0, width: 0, transition: '.1.2s' })
						}}
						onMouseLeave={() => {
							setCursorStyle({
								height: false,
								width: false,
								transition: false,
							})
						}}
					>
						<Iwrapp>
							{Image.imageFile.childImageSharp === null ? (
								<img
									loading="lazy"
									src={Image.imageFile.publicURL}
									alt={Image.alternativeText}
									title={Image.caption}
								/>
							) : (
								<Img
									fluid={Image.imageFile.childImageSharp.fluid}
									alt={Image.alternativeText}
									title={Image.caption}
								/>
							)}
						</Iwrapp>
					</Link>
				) : (
					<Iwrapp>
						{Image.imageFile.childImageSharp === null ? (
							<img
								loading="lazy"
								src={Image.imageFile.publicURL}
								alt={Image.alternativeText}
								title={Image.caption}
							/>
						) : (
							<Img
								fluid={Image.imageFile.childImageSharp.fluid}
								alt={Image.alternativeText}
								title={Image.caption}
							/>
						)}
					</Iwrapp>
				)}
			</Column>
		</Flex>
	)
})

const Header = styled.h3`
	font-size: 2.625rem;
	line-height: 3.75rem;
	max-width: 480px;
	margin-bottom: 2rem;

	@media (max-width: 1024px) {
		font-size: 2rem;
		line-height: 2.75rem;
		margin-bottom: 24px;
	}

	@media (max-width: 525px) {
		font-size: 1.875rem;
		line-height: 2.75rem;
	}
`

const Flex = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	margin-bottom: 240px;

	@media (max-width: 1024px) {
		margin-bottom: 12.8em;
	}

	@media (max-width: 525px) {
		margin-bottom: 9em;
	}
`
const Column = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: ${({ gradientdark, gradientlight }) => {
		return (
			'transparent linear-gradient(297deg, ' +
			gradientdark +
			' 0%, ' +
			gradientlight +
			' 100%) 0% 0% no-repeat padding-box;'
		)
	}};

	&:nth-of-type(odd) {
		flex-basis: 54.6%;
		max-width: 54.6%;
		display: flex;
		align-items: flex-start;
		padding-right: 128px;
	}

	&:nth-of-type(even) {
		display: flex;
		flex-basis: 46.4%;
		max-width: 45.4%;

		.gatsby-image-wrapper {
			max-height: 830px;
		}
	}

	.base-link {
		margin-top: 64px;
		background-color: ${({ theme }) => theme.buttonBg};
		color: ${({ theme }) => theme.buttonColor};
		padding: 9px 18px;
		text-transform: capitalize;
		font-weight: 600;
		border-radius: 3px;
		transition: opacity 0.25s;
		z-index: 1;

		&:hover {
			opacity: 0.7;
		}
	}

	.img-link {
		width: 100%;
		height: 100%;
	}

	@media (max-width: 1250px) {
		&:nth-of-type(odd) {
			flex-basis: 100%;
			max-width: 100%;
			padding-right: 0;
			margin-bottom: 4.8em;
		}

		&:nth-of-type(even) {
			flex-basis: 100%;
			max-width: 100%;

			.gatsby-image-wrapper {
				/* max-height: 553px; */

				& > div {
					/* padding-bottom: 30% !important; */
				}
			}
		}

		.base-link {
			margin-top: 32px;
		}
	}

	@media (max-width: 768px) {
		.base-link {
			font-size: 14px;
			padding: 7px 12px;
		}
	}
`

const Iwrapp = styled.div.attrs({
	className: 'image-wrapp',
})`
	position: relative;
	width: 100%;

	&:after {
		/* content: ''; */
		background-color: ${({ theme }) => theme.slider};
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition: transform 1.25s cubic-bezier(0.475, 0.425, 0, 0.995);
		transform-origin: top;
		transform: scale(1);
	}

	.gatsby-image-wrapper {
		/* opacity: 0;
		transform: translate3d(0, 100px, 0); */
		transition: all 4s cubic-bezier(0.19, 1, 0.22, 1);
		position: relative;
	}

	&.show {
		.gatsby-image-wrapper {
			opacity: 1;
			transform: translateZ(0);
		}

		&:after {
			transform: scaleY(0);
		}
	}

	picture img,
	img {
		transition: all 0.3s ease 0s !important;
	}

	&:hover {
		picture img,
		img {
			transform: scale(1.02);
		}
	}
`

export default ImageRightContentCta
