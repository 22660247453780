import React from 'react'
import styled from 'styled-components'

const Container = React.memo((props) => {
	const { children } = props

	return (
		<Wrapper {...props}>
			<div className="row">{children}</div>
		</Wrapper>
	)
})

const Wrapper = styled.section`
	.row {
		max-width: 140.8em;
		margin: 0 auto;
		padding: 27em 0;
	}

	@media (max-width: 1450px) {
		.row {
			padding-left: 5em;
			padding-right: 5em;
		}
	}

	@media (max-width: 1024px) {
		.row {
			padding: 15em 5em 12.8em 5em;
		}
	}

	@media (max-width: 525px) {
		.row {
			padding: 14em 2em 12.8em 2em;
		}
	}
`
export default Container
