import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Styles
import { Header, TitleColumn, Story, Looks, Journey } from './style'

// Ui components
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Heading from '../../components/ui/Heading'
import Container from '../../components/ui/Container'
import ImageRightContentCta from '../../components/ui/ImageRightContentCta'
import ImageLeftContentCta from '../../components/ui/ImageLeftContentCta'

const Services = React.memo((props) => {
	const data = useStaticQuery(graphql`
		query Services {
			timacum {
				page(id: "5ffeb5d7c8ad8075e98523fa") {
					Title
					Seo {
						TitleTag
						MetaDescription
					}
					Fields {
						... on TIMACUM_ComponentContentTitle {
							Title
							Layout
							Tag
						}
						... on TIMACUM_ComponentContentImageTitleText {
							id
							GradientDark
							GradientLight
							Layout
							Text
							Title
							CTA
							Link
							Image {
								url
								alternativeText
								caption
								imageFile {
									publicURL
									childImageSharp {
										fluid(maxWidth: 1920, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
							ImageLink
						}
						... on TIMACUM_ComponentContentImageRightText {
							id
							GradientDark
							GradientLight
							Layout
							Text
							Title
							Image {
								url
								alternativeText
								caption
								imageFile {
									publicURL
									childImageSharp {
										fluid(maxWidth: 1920, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
							ImageLink
						}

						... on TIMACUM_ComponentContentImageContentRight {
							id
							GradientDark
							GradientLight
							Layout
							Text
							Title
							Image {
								url
								alternativeText
								caption
								imageFile {
									publicURL
									childImageSharp {
										fluid(maxWidth: 1920, quality: 100) {
											...GatsbyImageSharpFluid
										}
									}
								}
							}
							ImageLink
						}
					}
				}
			}
		}
	`)

	const { Seo } = data.timacum.page
	return (
		<Layout location={props.location} customCTA="blockchain">
			<SEO data={Seo} />
			<Container>
				<TitleColumn>
					<span>our services</span>
					<Header as={Heading} level={1}>
						{data.timacum.page.Fields[0].Title}
					</Header>
				</TitleColumn>
				<Story
					as={ImageRightContentCta}
					fields={data.timacum.page.Fields[1]}
					level={2}
				/>
				<Looks
					as={ImageLeftContentCta}
					fields={data.timacum.page.Fields[2]}
					level={2}
				/>
				<Journey
					as={ImageRightContentCta}
					fields={data.timacum.page.Fields[3]}
					level={2}
					baseLink
				/>
			</Container>
		</Layout>
	)
})

export default Services
