import React, { useContext } from 'react'
import styled from 'styled-components'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import ThemeContext from '../../../context/ThemeContext'
import { useMediaPredicate } from 'react-media-hook'

const CTA = React.memo((props) => {
	const { link, children } = props
	const { theme } = useContext(ThemeContext)
	const mobileScreen = useMediaPredicate('(max-width: 1024px)')

	const setCursorStyle = (props) => {
		document.getElementById('cursor').style.cssText = props
	}

	return (
		<Wrapper {...props}>
			<Cta
				cover={!mobileScreen}
				bg={theme ? '#0C0C0C' : '#FFF'}
				direction="left"
				to={link}
				onMouseEnter={() =>
					setCursorStyle(
						'width:  50px; height: 50px; background-color: transparent; border: 1px solid white;'
					)
				}
				onMouseLeave={() => setCursorStyle(false)}
				onClick={() => setCursorStyle(false)}
			>
				{children}
			</Cta>
		</Wrapper>
	)
})

const Wrapper = styled.div.attrs({
	className: 'content-item',
})`
	overflow: hidden;
	display: flex;
	justify-content: flex-end;
	width: 100%;
`

const Cta = styled(AniLink)`
	font: 1rem/1.3125rem 'RobotoMono Bold';
	padding: 10px 0;
	position: relative;
	padding-left: 6.25em;
	color: ${({ theme }) => theme.link};
	transition: opacity 0.3s, padding-left 0.3s;

	&:before {
		content: '';
		position: absolute;
		left: -10px;
		top: 50%;
		transform: translateY(-50%);
		height: 1px;
		width: 90px;
		transition: width 0.3s;
		mix-blend-mode: difference;
		background-color: white;
	}

	&:hover {
		padding-left: 3em;

		&:before {
			width: 40px;
		}
	}

	@media (max-width: 1024px) {
		font-size: 0.6875rem;
		line-height: 0.9375rem;

		&:before {
			width: 50px;
		}
	}

	@media (max-width: 525px) {
		font-size: 0.75rem;
		line-height: 1rem;
		padding-left: 4.65em;

		&:before {
			width: 40px;
		}
	}
`

export default CTA
